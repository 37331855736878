import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useApolloClient, gql } from '@apollo/client';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';

import { delay, useAnalytics, trackBackEnd } from 'context/AnalyticsProvider';
import { fadeIn, fadeOut } from 'libs/animations';
import Error from 'components/error/Error';
import Rate from './Rate';
import InputField from 'components/forms/InputField';
import Button from 'components/forms/Button';
import BlankButton from 'components/forms/BlankButton';
import Spacing from 'layouts/Spacing';
import { PrivacyPolicyLink } from 'components/consent-portal/useSaveConsent';
import Box from 'components/forms/Box';

const Head = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Question = styled.h2`
	display: block;
	text-align: center;
	font-weight: 600;
	margin-bottom: ${p => p.theme.spacing.desktop.medium};
	${p =>
		p.theme.media.mediumOnly(css`
			margin-bottom: ${p => p.theme.spacing.tablet.medium};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			margin-bottom: ${p => p.theme.spacing.mobile.medium};
		`)}
`;

const Number = styled.p`
	font-weight: 600;
	text-transform: uppercase;
	color: ${p => p.theme.colors.blue600};
	margin: 0;
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 14px;
			line-height: 22px;
		`)}
`;

const PrevBtn = styled(BlankButton)`
	margin-right: auto;

	&:disabled {
		color: ${p => p.theme.colors.grey500} !important;
		pointer-events: none;
	}
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
		`)}
	svg {
		margin-right: 10px;
	}
`;

const NextBtn = styled(BlankButton)`
	margin-left: auto;
	&:disabled {
		color: ${p => p.theme.colors.grey500} !important;
		pointer-events: none;
	}
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
		`)}
	svg {
		margin-left: 10px;
	}
`;

const BoxContainer = styled(Box)`
	animation-name: ${p => (p.fade === 'in' ? fadeIn : fadeOut)};
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
`;

const Step = styled.div`
	text-align: center;
`;

const Help = styled.div`
	color: ${p => p.theme.colors.grey700};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin-top: 5px;
	text-align: center;
`;

const SUBMIT_CUSTOMER_SURVEY = gql`
	mutation submitCustomerSurvey(
		$answers: JSON!
		$t_id: String!
		$s_id: String!
		$a_id: String
		$phone: String!
	) {
		success: submitCustomerSurvey(
			answers: $answers
			t_id: $t_id
			s_id: $s_id
			a_id: $a_id
			phone: $phone
		)
	}
`;

/**
 * Renders a survey form with dynamic question and navigation handling.
 * @returns {JSX.Element|null} - The survey form as a JSX element, or null if there are no survey questions.
 * @param {string} t_id - Task ID of the inquiry
 * @param {string} s_id - Service ID of the inquiry
 * @param {string} a_id - Agent ID of the inquiry
 * @param {string} phone - Phone number of the inquiry
 */
export default function Survey({ questions, t_id, s_id, a_id, phone }) {
	const client = useApolloClient();
	const { identify } = useAnalytics();

	const [fade, setFade] = useState('in');
	const [step, setStep] = useState(0);
	const [answers, setAnswers] = useState([]);
	const [disableNextButton, setDisableNextButton] = useState(true);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState(false);

	// Get the current question from the questions array.
	const currentQuestion = questions?.length > 0 && questions[step];

	// Retrieves the current answer object from the answers array based on the current step.
	const currentAnswer =
		answers?.length > 0 && answers.find(a => a.step === step + 1);

	/**
	 * This function is used to submit survey data to the server.
	 * @async
	 * @function
	 * @param {Event} e - The event object from form submission.
	 * @returns {void}
	 */
	async function submitSurvey(e) {
		e.preventDefault();

		// If no t_id, s_id or phone exists then don´t proceed
		if (!t_id || !s_id || !phone) return;

		// Find the questions with the type "textarea"
		const textFields =
			questions?.length > 0 &&
			questions.filter(q => q.type === 'textarea');

		// If there are any textareas then loop through
		if (textFields?.length > 0) {
			textFields.forEach(field => {
				// Find the index of the question
				const index = questions.findIndex(q => q.id === field?.id);

				// If no index, title or value then exit
				if (!index || !field?.title || !e?.target[field?.id]?.value)
					return;

				// Find out if there´s already an answer stored for this question
				const currentAnswer = answers.find(
					a => a.question === field?.title
				);

				// Find the index if the previously stored answer
				const currentAnswerIndex = answers?.findIndex(
					a => a.question === field?.title
				);

				/**
				 * If there´s already an answer stored for this question
				 * then update the value, otherwise push to answer-array
				 */
				if (currentAnswer && currentAnswerIndex) {
					answers[currentAnswerIndex].answer =
						e?.target[field?.id]?.value;
				} else {
					answers.push({
						step: index + 1,
						question: field?.title,
						answer: e?.target[field?.id]?.value,
					});
				}
			});
		}

		try {
			if (!answers?.length > 0) {
				throw new Error('No answers in array to submit');
			}

			// Duplicate the array
			const answersDupe = answers;

			// Delete the value "step" from each object in array
			answersDupe.forEach(object => {
				delete object['step'];
			});

			setSubmitError(false);
			setSubmitting(true);

			// Identify user in Segment
			await identify({ phone });

			// Delay
			await delay();

			const { data, error, loading } = await client.mutate({
				mutation: SUBMIT_CUSTOMER_SURVEY,
				variables: {
					answers: answersDupe,
					t_id,
					s_id,
					a_id: a_id || undefined,
					phone,
				},
			});

			if (error && !loading) {
				throw new Error(
					'Submitting survey answers failed:',
					error.message
				);
			}

			if (data?.success && !loading) {
				setTimeout(() => {
					trackBackEnd('Step Completed', trackStepCompleted(5));
					setFormSubmitted(true);
				}, 250);
			}
		} catch (e) {
			setSubmitError(true);
			setSubmitting(false);
			console.log(e);
		}

		return;
	}

	/**
	 * Navigate to the previous or next step of the survey and update the UI.
	 * @param {Event} e - The button click event that triggers the navigation.
	 * @param {string} direction - The direction of the navigation, either 'prev' or 'next'.
	 */
	function navigateStep(e, direction) {
		e.preventDefault();

		// If the direction is not specified or invalid, return without doing anything.
		if (!direction) return;

		// Fade out the current step to prepare for the transition.
		setFade('out');

		// Wait for 500ms to allow the fade animation to complete.
		setTimeout(() => {
			// Update the step counter based on the navigation direction.
			if (direction === 'prev') {
				setStep(step - 1);
			} else {
				setStep(step + 1);
			}
			// Fade in the new step to complete the transition.
			setFade('in');
		}, 500);

		return;
	}

	/**
	 * Store or update an answer
	 * @param {object} answer - The answer object containing step, value and title
	 * @param {number} answer.step - The current step
	 * @param {string|number} answer.value - The value of the answer
	 * @param {string} answer.title - The title of the current question
	 * @returns {void}
	 */
	function storeAnswer({ value, step, title }) {
		if (!value || !step) return;

		setAnswers(prev => {
			const newAnswers = [...prev];
			const currentAnswerIndex = newAnswers.findIndex(
				a => a.step === step
			);

			if (currentAnswerIndex !== -1) {
				newAnswers[currentAnswerIndex] = {
					...newAnswers[currentAnswerIndex],
					answer: value,
				};
			} else {
				newAnswers.push({
					step,
					question: title,
					answer: value,
				});
			}

			return newAnswers;
		});

		// Set fade animation to 'out'
		setFade('out');
		// Wait 500ms and then update step and fade animation to 'in'
		setTimeout(() => {
			setStep(step);
			setFade('in');
		}, 500);
		return;
	}

	const trackStepCompleted = step => {
		if (!step) return;
		return {
			category: 'Kundeservice',
			properties: {
				step,
				entry: 'Kundeservice',
				label: 'Kundeundersøkelse',
				answers,
				t_id,
				s_id,
				a_id,
				phone,
			},
		};
	};

	useEffect(() => {
		// Track each completed step in survey
		if (step === 1) trackBackEnd('Step Completed', trackStepCompleted(1));
		if (step === 2) trackBackEnd('Step Completed', trackStepCompleted(2));
		if (step === 3) trackBackEnd('Step Completed', trackStepCompleted(3));
		if (step === 4) trackBackEnd('Step Completed', trackStepCompleted(4));

		if (!currentAnswer || typeof currentAnswer === 'undefined') {
			setDisableNextButton(true);
			return;
		}
		if (step + 1 === currentAnswer?.step) {
			setTimeout(() => {
				setDisableNextButton(false);
			}, 1000);
		} else {
			setDisableNextButton(true);
		}

		//eslint-disable-next-line
	}, [step]);

	/**
	 * Check if there are any survey questions to display and return null if there are none.
	 * @returns {null|undefined} - If there are no survey questions, returns null. Otherwise, returns undefined.
	 */
	if (!questions?.length > 0) return null;

	/**
	 * Check if the survey has been submitted and redirect to the thank you page if it has.
	 */
	if (formSubmitted) {
		navigate('/kundeservice/tilbakemelding/takk');
	}

	// Render the survey form.
	return (
		<form onSubmit={e => submitSurvey(e)}>
			{/**
			 * Render the appropriate navigation buttons based on the current survey step and answer status.
			 *
			 * @param {string} fade - The fade status, used to control button opacity and transition effects.
			 *
			 * @returns {JSX.Element} - The navigation buttons as a JSX element.
			 */}
			<Spacing spacing={{ bottom: 'medium' }}>
				<Head>
					<PrevBtn
						onClick={e => navigateStep(e, 'prev')}
						title="Gå tilbake til forrige spørsmål"
						disabled={step === 0 || step === questions?.length}>
						<FontAwesomeIcon icon={faArrowLeft} size="sm" />
						<span>Forrige</span>
					</PrevBtn>

					<div>
						<Number>{`Spørsmål ${step + 1} av ${questions?.length
							}`}</Number>
					</div>

					<NextBtn
						onClick={e => navigateStep(e, 'next')}
						title="Gå til neste spørsmål"
						disabled={disableNextButton}>
						<span>Neste</span>
						<FontAwesomeIcon icon={faArrowRight} size="sm" />
					</NextBtn>
				</Head>
			</Spacing>

			<Spacing spacing={{ bottom: 'medium' }}>
				<BoxContainer fade={fade}>
					<Question htmlFor={currentQuestion?.id} className="h3">
						{questions?.length > 0 && currentQuestion?.title}
					</Question>

					<Step>
						{(currentQuestion?.type === 'rate' && (
							<Rate
								currentQuestion={currentQuestion}
								currentAnswer={currentAnswer}
								saveRating={(e, rate) => {
									e.preventDefault();
									storeAnswer({
										step: step + 1,
										value: rate,
										title: currentQuestion?.title,
									});

									// Remove foucs from the button
									document.activeElement.blur();
								}}
							/>
						)) || (
								<InputField
									label="Andre tilbakemeldinger"
									id={currentQuestion?.id}
									name={currentQuestion?.id}
									defaultValue={currentAnswer?.answer}
									textarea
								/>
							)}
					</Step>

					{questions?.length === step + 1 && (
						<>
							<Button
								type="submit"
								text="Send inn"
								loading={submitting?.toString()}
								disabled={submitting}>
								{submitting ? 'Sender inn' : 'Send inn'}
							</Button>
							<PrivacyPolicyLink
								style={{
									textAlign: 'left',
									marginBottom: '0',
									marginTop: '20px',
								}}
							/>
						</>
					)}
				</BoxContainer>
				{submitError && !submitting && (
					<Error
						style={{ marginTop: '40px' }}
						resolver="submitSurvey"
						errorMessage="Survey submission failed"
					/>
				)}
			</Spacing>
			{currentQuestion?.desc && <Help>{currentQuestion?.desc}</Help>}
		</form>
	);
}
